<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group>
                  <v-select
                    v-model="filter.trainingSystemId"
                    :options="trainingSystems"
                    :reduce="option => option.value"
                    :clearable="false"
                    placeholder="Chương trình đào tạo/Bồi dưỡng"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group>
                  <v-select
                    v-model="filter.courseId"
                    :options="courses"
                    :reduce="option => option.value"
                    placeholder="Khóa"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group>
                  <v-select
                    v-model="filter.classId"
                    :options="classes"
                    :reduce="option => option.value"
                    placeholder="Lớp cố định"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-input
                  v-model="filter.student"
                  placeholder="Tên/Mã SV"
                />
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group>
                  <v-select
                    v-model="filter.awardId"
                    :options="factAwards"
                    :reduce="option => option.id"
                    :menu-props="{ top: true, offsetY: true }"
                    label="name"
                    placeholder="Hình thức khen thưởng"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="8"
              >
                <div class="text-center text-md-right mt-md-0 mt-1">
                  <b-button
                    v-if="creatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    @click="onSearchStudentAward"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="FilterIcon" /> Danh sách học viên được khen thưởng
                    </span>
                  </b-button>
                  <b-button
                    v-if="creatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="info"
                    class="mr-1"
                    @click="onSearchStudent"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="ListIcon" /> Danh sách học viên đủ điều kiện khen thưởng
                    </span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    @click="onApprove(1)"
                  >
                    <feather-icon icon="CheckIcon" /> Chấp nhận
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  :columns="columns"
                  :rows="studentAwards"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :select-options="{ enabled: checkColumn, selectOnCheckboxOnly: true, selectionText: 'HV được chọn', clearSelectionText: 'Bỏ chọn' }"
                  @on-selected-rows-change="selectionChanged"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'stt'">
                      {{ props.row.originalIndex + 1 }}
                    </span>
                    <span
                      v-if="props.column.field === 'status'"
                      :class="(props.row.status === -1) ? 'text-danger font-weight-bold' : 'text-success font-weight-bold'"
                    >
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span v-else-if="props.column.field === 'action'">
                      <b-button
                        v-if="deletable"
                        variant="danger"
                        class="btn-icon"
                        style="margin-left: 6px"
                        size="sm"
                        @click="onDeleteStudentAward(props.row)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <studentAwardSave
      ref="studentAwardsSaveFormRef"
      :data-source="currentStudentAward"
      @succeed="onSucceed"
    />
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormGroup, BFormInput, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import StudentAwardSave from '@/views/student-award/StudentAwardSave.vue'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'

export default {
  name: 'StudentAward',
  directives: {
    Ripple,
  },
  components: {
    BFormInput,
    BFormGroup,
    vSelect,
    StudentAwardSave,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: getUser().orgId,
        trainingSystemId: null,
        courseId: null,
        courseSemesterId: null,
        classId: null,
        awardId: null,
        student: null,
        status: null,
        sort: null,
      },
      currentStudentAward: undefined,
      paginationOptions: {
        enabled: true,
      },
      checkColumn: false,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      selectedMainData: [],
    }
  },
  computed: {
    ...mapGetters({
      studentAwards: 'studentAward/studentAwards',
      totalRows: 'studentAward/totalRows',
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'dropdown/courses',
      courseSemesters: 'dropdown/courseSemesters',
      classes: 'dropdown/classes',
      factAwards: 'factAward/dataSources',
      statuses: 'studentAward/statuses',
    }),
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.STUDENTAWARD)
    },
    updatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.STUDENTAWARD)
    },
    deletable() {
      return hasPermissionForResource(PermissionCode.DELETE, ResourceCode.STUDENTAWARD)
    },
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
    columns() {
      const columns = [
        {
          label: 'STT',
          field: 'stt',
          width: '80px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Tên học viên',
          field: 'student',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Lớp cố định',
          field: 'className',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Khóa học',
          field: 'courseName',
          sortable: false,
          thClass: 'text-center',
        },
      ]
      if (!this.checkColumn) {
        columns.push({
          label: 'Loại khen thưởng',
          field: 'award',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
        }, {
          label: '',
          field: 'action',
          width: '100px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        })
      }
      if (this.checkColumn) {
        columns.push({
          label: 'Kết quả học tập toàn khóa',
          field: 'cgpaTenth',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Điểm rèn luyện',
          field: 'conductPoint',
          filterOptions: {
            enabled: false,
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
          {
          label: 'Xử lý',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.statusFilterDropdownItems,
            placeholder: 'Tất cả',
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        })
      }
      return columns
    },
  },

  watch: {
    'filter.trainingSystemId': function (newVal) {
      this.onTrainingSystemsChange(newVal)
    },
    'filter.courseId': function (newVal) {
      this.onCoursesChange(newVal)
    },
    // 'filter.departmentId': function (newVal) {
    //   this.onDepartmentsChange(newVal)
    // },
    'filter.majorId': function (newVal) {
      this.onMajorsChange(newVal)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.getTrainingSystems({ organizationId: getUser().orgId })

      if (this.trainingSystems.length > 0) {
        this.filter.trainingSystemId = this.trainingSystems[0].value
      }

      await Promise.all([
        this.getDepartments({ organizationId: getUser().orgId }),
        this.getCourses({ trainingSystemId: this.filter.trainingSystemId, organizationId: getUser().orgId }),
        this.getFactAwards({
          currentPage: 1,
          itemsPerPage: 100,
        }),
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapMutations({
      setCourses: 'dropdown/SET_COURSES',
      setCourseSemester: 'dropdown/SET_COURSE_SEMESTERS',
      setMajors: 'dropdown/SET_MAJORS',
      setClasses: 'dropdown/SET_CLASSES',
    }),
    ...mapActions({
      getStudentAwards: 'studentAward/getStudentAwards',
      getEligibleStudents: 'studentAward/getEligibleStudents',
      deleteStudentAward: 'studentAward/deleteStudentAward',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getClasses: 'dropdown/getClasses',
      getFactAwards: 'factAward/getDataSources',
      updateData: 'studentAward/createData',
    }),
    async onTrainingSystemsChange(event) {
      this.setCourses([])
      this.filter.courseId = null
      this.setClasses([])
      this.filter.classId = null
      await this.getCourses({
        organizationId: getUser().orgId,
        trainingSystemId: event,
      })
    },
    async onCoursesChange(event) {
      this.filter.classId = null
      this.filter.courseSemesterId = null
      await this.getCourseSemesters({
        organizationId: getUser().orgId,
        courseId: event,
      })
      await this.getClasses({
        organizationId: getUser().orgId,
        courseId: this.filter.courseId,
        // departmentId: this.filter.departmentId,
      })
    },
    onCreateStudentAward() {
      this.currentStudentAward = undefined
    },
    async onSearchStudentAward() {
      this.checkColumn = false
      await this.getStudentAwardsFromStore()
    },
    async onSearchStudent() {
      this.checkColumn = true
      await this.getStudentFromStore()
    },
    onDeleteStudentAward(studentAward) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa đạt khen thưởng <span class="text-danger">${studentAward.student}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteStudentAward(studentAward.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getStudentAwards(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    selectionChanged(params) {
      this.selectedMainData = params ? params.selectedRows.map(e => ({ studentCgpaId: e.id })) : []
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getStudentAwardsFromStore()
    },
    async getStudentAwardsFromStore() {
      this.isLoading = true
      try {
        await this.getStudentAwards(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async getStudentFromStore() {
      this.isLoading = true
      try {
        await this.getEligibleStudents(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getStudentAwardsFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'name') this.updateParams({ sort: `1_${type}` })
        else if (field === 'code') this.updateParams({ sort: `2_${type}` })
        else this.updateParams({ sort: `3_${type}` })
      }
      await this.getStudentAwardsFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getStudentAwardsFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    async onSucceed() {
      await this.getStudentAwardsFromStore()
    },
    async onApprove(status) {
      if (this.filter.awardId === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn hình thức khen thưởng!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return null
      }
      if (this.selectedMainData.length < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn Học viên!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return null
      }
      this.isLoading = true
      try {
        const request = {
          studentCgpaIds: this.selectedMainData,
          awardId: this.filter.awardId,
          status,
        }
        const response = await this.updateData(request)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            await this.getStudentFromStore()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
      return null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
